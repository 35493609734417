<!--
 * @Description: 实况信息
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-05-25 09:06:10
 * @LastEditors: 支磊
 * @LastEditTime: 2021-09-27 10:03:27
-->
<template>
  <div class="nowlist">
    <div class="select">
      <el-input
        style="width: 450px"
        placeholder="请输入内容"
        prefix-icon="el-icon-search"
        size="small"
        v-model="searchString"
      >
      </el-input>
    </div>
    <div class="table">
      <el-table
        :data="tableData"
        style="width: 100%"
        height="100%"
        @row-click="rowClickF"
      >
        <el-table-column prop="name" label="设备名称"> </el-table-column>
        <el-table-column align="center" prop="installPosition" label="安装位置">
        </el-table-column>
        <el-table-column align="center" prop="status" label="实时状态">
          <template slot-scope="scope">
            {{ statusArr[scope.row.status] }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="风险系数">
          <template slot-scope="scope"> {{ scope.row.riskNum }}% </template>
        </el-table-column>
        <el-table-column align="center" label="最近修改时间">
          <template slot-scope="scope">
            {{ scope.row.updateTime | time }}
          </template>
        </el-table-column>
        <template slot="empty">
          <div>
            <img style="width: 96px" src="/img/notData.png" alt="" />
            <p style="color: #909399; font-size: 14px; text-align: center">
              暂无数据
            </p>
          </div>
        </template>
      </el-table>
    </div>
    <div class="page">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!--  -->
    <info v-if="infoShow" @close="closeF" :info="info" />
    <!--  -->
  </div>
</template>

<script>
import { equipmentStatusList } from "@/api/rule.js";
import info from "./nowlistInfo";
export default {
  name: "nowlist",
  components: { info },
  data() {
    return {
      infoShow: false,
      info: {},
      axial: [0, "x(A)", "y(B)", "z(C)"],
      statusArr: ["正常", "低报", "高报", "高高报"],
      searchString: "",
      pageSize: 10,
      currentPage: 1,
      tableData: [],
      total: 0,
      timeF: null,
    };
  },
  filters: {
    time(data) {
      let time = "";
      let format = "yyyy-MM-dd HH:mm:ss";
      var t = new Date(data);
      var tf = function (i) {
        return (i < 10 ? "0" : "") + i;
      };
      time = format.replace(/yyyy|MM|dd|HH|mm|ss/g, function (a) {
        let date = "";
        switch (a) {
          case "yyyy":
            date = tf(t.getFullYear());
            break;
          case "MM":
            date = tf(t.getMonth() + 1);
            break;
          case "mm":
            date = tf(t.getMinutes());
            break;
          case "dd":
            date = tf(t.getDate());
            break;
          case "HH":
            date = tf(t.getHours());
            break;
          case "ss":
            date = tf(t.getSeconds());
            break;
        }
        return date;
      });
      return time;
    },
  },
  computed: {
    deviceIds() {
      return sessionStorage.getItem("deviceIds");
    },
  },
  //监听执行
  watch: {
    searchString() {
      clearTimeout(this.timeF);
      this.timeF = setTimeout(() => {
        this.currentPage = 1;
        this.getData();
      }, 1200);
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    closeF(data) {
      this.infoShow = false;
    },
    rowClickF(data) {
      console.log(data);
      this.info = {
        ...data,
      };
      this.infoShow = true;
    },
    getData() {
      this.tableData = [];
      let data = {
        searchString: this.searchString,
        pageNo: this.currentPage,
        pageSize: this.pageSize,
      };
      equipmentStatusList(data).then((res) => {
        if (this.apiReply(res)) {
          this.tableData = this.tableData.concat(res.data.data.dataList);
          let arr = [];
          if (this.deviceIds) {
            arr = this.deviceIds.split(",");
          }
          console.log("ids", arr);
          this.tableData = this.tableData.filter((item) => {
            if (arr.includes(item.id + "")) {
              return true;
            }
          });
          console.log(this.tableData);
          this.total = res.data.data.total;
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData();
    },
  },
};
</script>
<style lang="scss" scoped>
.nowlist {
  height: 100%;
  padding: 20px 44px 24px;
  .select {
    display: flex;
    justify-content: space-between;
  }
  .table {
    height: calc(100% - 92px);
    overflow: auto;
    padding-top: 10px;
  }
  .page {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .delete {
    cursor: pointer;
    color: $delete_color;
  }
}
</style>
