<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-07-08 15:16:04
 * @LastEditors: 支磊
 * @LastEditTime: 2021-07-09 15:50:07
-->
<template>
  <div class="nowlistInfo">
    <div class="model"></div>
    <div class="main">
      <span class="close el-icon el-icon-close" @click="close"></span>
      <div class="title">{{ info.name }}</div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="振动测点" name="zdcd">
          <div style="text-align: left">
            <el-input
              placeholder="请输入测点名称"
              v-model="input"
              size="small"
              class="input-with-select"
              style="width: 280px"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="searchF"
              ></el-button>
            </el-input>
          </div>
          <!-- table -->
          <el-table :data="tableData" style="width: 100%" height="100%">
            <el-table-column prop="pointName" label="测点名称" width="180">
            </el-table-column>
            <el-table-column label="测点类型" width="180">
              <template slot-scope="scope">
                {{ pointType[scope.row.pointCategory] }}
              </template>
            </el-table-column>
            <el-table-column prop="address" label="报警等级"> </el-table-column>
            <el-table-column prop="acceleration" label=" 加速度峰值(X/Y/Z)">
            </el-table-column>
            <el-table-column prop="velocity" label="速度均方根值(X/Y/Z)">
            </el-table-column>
            <el-table-column prop="temperature" label="温度(℃)">
            </el-table-column>
            <el-table-column label="最近更新">
              <template slot-scope="scope">
                {{ scope.row.updateTime | time }}
              </template>
            </el-table-column>
          </el-table>
          <!-- table end -->
        </el-tab-pane>
        <el-tab-pane label="电流电压测点" name="dldycd">
          <!-- 电流电压测点 -->
          <div style="text-align: left">
            <el-input
              placeholder="请输入测点名称"
              v-model="input"
              size="small"
              class="input-with-select"
              style="width: 280px"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="searchF"
              ></el-button>
            </el-input>
          </div>
          <!-- table -->
          <el-table :data="tableData" style="width: 100%" height="100%">
            <el-table-column prop="pointName" label="测点名称" width="180">
            </el-table-column>
            <el-table-column label="测点类型" width="180">
              <template slot-scope="scope">
                {{ pointType[scope.row.pointCategory] }}
              </template>
            </el-table-column>
            <el-table-column prop="address" label="报警等级"> </el-table-column>
            <el-table-column prop="currents" label=" 电流值(X/Y/Z)">
            </el-table-column>
            <el-table-column prop="voltage" label="电压值(X/Y/Z)">
            </el-table-column>

            <el-table-column label="最近更新">
              <template slot-scope="scope">
                {{ scope.row.updateTime | time }}
              </template>
            </el-table-column>
          </el-table>
          <!-- table end -->
          <!-- 电流电压测点 end -->
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { listPointRealTimeData } from "@/api/point.js";
export default {
  name: "nowlistInfo",
  components: {},
  props: {
    info: {
      type: Object,
      default: () => {
        return {
          name: "",
        };
      },
    },
  },
  data() {
    return {
      pointType: {
        // 测点类型
        1: "固定",
        2: "点检",
        3: "巡检",
      },
      input: "",
      tableData: [],
      activeName: "zdcd",
    };
  },
  filters: {
    time(data) {
      let time = "";
      let format = "yyyy-MM-dd HH:mm:ss";
      var t = new Date(data);
      var tf = function (i) {
        return (i < 10 ? "0" : "") + i;
      };
      time = format.replace(/yyyy|MM|dd|HH|mm|ss/g, function (a) {
        let date = "";
        switch (a) {
          case "yyyy":
            date = tf(t.getFullYear());
            break;
          case "MM":
            date = tf(t.getMonth() + 1);
            break;
          case "mm":
            date = tf(t.getMinutes());
            break;
          case "dd":
            date = tf(t.getDate());
            break;
          case "HH":
            date = tf(t.getHours());
            break;
          case "ss":
            date = tf(t.getSeconds());
            break;
        }
        return date;
      });
      return time;
    },
  },
  computed: {},
  //监听执行
  watch: {
    activeName() {
      this.input = "";

      if (this.activeName == "zdcd") {
        this.listPointRealTimeDataF();
      } else {
        this.listPointRealTimeDataF(9);
      }
    },
  },
  mounted() {
    this.listPointRealTimeDataF();
  },
  methods: {
    searchF() {
      // 搜索
      if (this.activeName != "zdcd") {
        this.listPointRealTimeDataF(9);
      } else {
        this.listPointRealTimeDataF();
      }
    },
    listPointRealTimeDataF(pointCategory) {
      let data = {
        pageNo: 1,
        pageSize: 20,
        searchString: this.input,
        deviceId: this.info.id,
      };
      if (pointCategory) {
        data.pointCategory = pointCategory;
      }
      listPointRealTimeData(data).then((res) => {
        if (this.apiReply(res)) {
          this.tableData = [...res.data.data];
          console.log(res.data.data);
        }
      });
    },
    close(data) {
      this.$emit("close", data);
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>
<style lang="scss" scoped>
.nowlistInfo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  .model {
    position: absolute;
    @extend .model;
  }
  .main {
    padding: 28px;
    width: 80%;
    max-width: 1200px;
    height: 686px;
    background: #fff;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    .close {
      color: #909399;
      font-size: 24px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      z-index: 9;
    }
    .title {
      @extend .title_green;
    }
    /deep/.el-tabs {
      height: 100%;
      .el-tab-pane {
        height: 100%;
      }
    }
    /deep/.el-tabs .el-tabs__content {
      height: calc(100% - 60px);
    }
  }
}
</style>
