/*
 * @Description: 规则接口
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2020-10-19 11:48:35
 * @LastEditors: 支磊
 * @LastEditTime: 2021-11-23 14:55:40
 */
import base from '@/config'; // 导入接口域名列表
import axios from '@/utils/cmshttps'; // 导入http中创建的axios实例
import qs from 'qs'

function headerF() {
  let header = {}
  if (localStorage.getItem("SiteId") && localStorage.getItem("SiteId") != -1) {
    header = {
      'SiteId': localStorage.getItem("SiteId")
    }
  }
  return header
}
export function list(data) {
  let header = headerF()
  return axios.get(`${base.cmsBaseApi}/rule/v2/list?searchString=${data.searchString}&pageNo=${data.pageNo}&pageSize=${data.pageSize}`, {
    headers: {
      ...header
    }
  });
}
export function equipmentStatusList(data) {
  let header = headerF() //实况信息
  return axios.get(`${base.cmsBaseApi}/device/v2/equipmentStatusList?searchString=${data.searchString}&pageNo=${data.pageNo}&pageSize=${data.pageSize}`, {
    headers: {
      ...header
    }
  });
}
export function filterList(data) {
  let header = headerF() //报警记录
  return axios.post(`${base.cmsBaseApi}/alarm/v2/filterList`, data, {
    headers: {
      ...header
    }
  });
}
export function create(data) {
  let header = headerF() //创建报警规则
  return axios.post(`${base.cmsBaseApi}/rule/v2/create`, data, {
    headers: {
      ...header
    }
  });
}
export function deleteApi(data) {
  let header = headerF() //删除报警规则
  return axios.post(`${base.cmsBaseApi}/rule/v2/delete`, data, {
    headers: {
      ...header
    }
  });
}
export function filterTree() {
  let header = headerF() //报警记录-过滤信息树
  return axios.get(`${base.cmsBaseApi}/dept/v2/filterTree`, {
    headers: {
      ...header
    }
  });
}
export function filter() {
  let header = headerF() //报警记录-过滤信息
  return axios.get(`${base.cmsBaseApi}/alarm/v2/filter`, {
    headers: {
      ...header
    }
  });
}
export function alarmupdate(data) {
  //   id: 43391
  // processOpinion: "没有"
  let header = headerF() //报警记录-处理
  return axios.put(`${base.cmsBaseApi}/alarm/v2/update`, data, {
    headers: {
      ...header
    }
  });
}
export function pointRuleList(data) {
  let header = headerF() //获取报警记录用于选择
  return axios.get(`${base.cmsBaseApi}/rule/v2/pointRuleList?searchString=${data.searchString}&pageNo=${data.pageNo}&pageSize=${data.pageSize}&rejectIds=${data.rejectIds}`, {
    headers: {
      ...header
    }
  });
}
export function detail(data) {
  let header = headerF() //获取报警记录信息
  return axios.get(`${base.cmsBaseApi}/rule/v2/detail?id=${data}`, {
    headers: {
      ...header
    }
  });
}
export function update(data) {
  let header = headerF() //编辑报警规则
  return axios.put(`${base.cmsBaseApi}/rule/v2/update`, data, {
    headers: {
      ...header
    }
  });
}