/*
 * @Description: 选择场景时用到的接口
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2020-10-19 11:48:35
 * @LastEditors: 支磊
 * @LastEditTime: 2021-10-22 11:28:54
 */
import base from '@/config'; // 导入接口域名列表
import axios from '@/utils/cmshttps'; // 导入http中创建的axios实例
import qs from 'qs'

function headerF() {
  let header = {}
  if (localStorage.getItem("SiteId") && localStorage.getItem("SiteId") != -1) {
    header = {
      'SiteId': localStorage.getItem("SiteId")
    }
  }
  return header
}
export function list(data) {
  return axios.get(`${base.cmsBaseApi}/point/v2/list?deviceId=${data.deviceId}&pageNo=${data.pageNo}&pageSize=${data.pageSize}&searchString=${data.searchString}`, {
    headers: {
      'Accept-Language': 'zh', //设置请求头请求格式为JSON
      'SiteId': localStorage.getItem("SiteId")
    }
  });
}
export function deletePoint(data) {
  let header = headerF() //删除测点
  return axios.post(`${base.cmsBaseApi}/point/v2/delete`, data, {
    headers: {
      ...header
    }
  });
}
export function sensorTypeList() {
  let header = headerF() //传感器类型列表
  return axios.get(`${base.cmsBaseApi}/sensor/v2/sensorType/list`, {
    headers: {
      ...header
    }
  });
}
export function availableList(data) {
  let header = headerF() //传感器列表
  return axios.get(`${base.cmsBaseApi}/sensor/v2/available/list?sensorType=${data}`, {
    headers: {
      ...header
    }
  });
}
export function create(data) {
  let header = headerF() //添加测点
  return axios.post(`${base.cmsBaseApi}/point/v2/create`, data, {
    headers: {
      ...header
    }
  });
}
export function detail(data) {
  let header = headerF() //测点详情
  return axios.get(`${base.cmsBaseApi}/point/v2/detail?id=${data}`, {
    headers: {
      ...header
    }
  });
}
export function update(data) {
  let header = headerF() //编辑测点
  return axios.put(`${base.cmsBaseApi}/point/v2/update`, data, {
    headers: {
      ...header
    }
  });
}
export function listPointRealTimeData(data) {
  let header = headerF() //测点详情
  if (data.pointCategory) {
    return axios.get(`${base.cmsBaseApi}/point/v2/listPointRealTimeData?pageNo=${data.pageNo}&pageSize=${data.pageSize}&searchString=${data.searchString}&deviceId=${data.deviceId}&pointCategory=${data.pointCategory}`, {
      headers: {
        ...header
      }
    });
  } else {
    return axios.get(`${base.cmsBaseApi}/point/v2/listPointRealTimeData?pageNo=${data.pageNo}&pageSize=${data.pageSize}&searchString=${data.searchString}&deviceId=${data.deviceId}`, {
      headers: {
        ...header
      }
    });
  }

}